<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
      <header>
        <h3>2025 Uniform Trophies </h3>
        <p>The Newfoundland Club of America welcomes your donation to sponsor NCA Lenox China Uniform Trophies for the
          2025 National Specialty.</p>
        <p>For trophies to be listed in the catalog, premium list and presented in the ring: Donations will be
          recognized as a general donation (example: &quot;Donated by XXXX Newfoundlands&quot;) or donations may be made to
          recognize some special individual or dog. Donations may not be made in honor of any living dog, but can be
          made &quot;in memory of&quot; deceased dogs. (Example: &quot;Donated by XXXXXX in memory of CH XXXXX&quot;)</p>
        <p>Please fill out the sponsorship field carefully, the catalog will be printed from this submission.</p>
          <p></p>
        <p>If you have questions, please contact <a href="mailto:katnewfz@aol.com">Kathy Hamilton</a></p>
        <p>Deadline is November 15, 2024.</p>
        <p *ngIf="productNotAvailable"><strong>Trophies are not available for sale at this time.</strong></p>
        <p *ngIf="pastDeadline"><strong>Trophies are no longer available for sale</strong></p>
      </header>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <h2>Regular Classes</h2>
          <table class="table table-bordered table-hover table-sm">
            <tr>
              <td>Name</td>
              <td>Description</td>
              <td>Price</td>
              <td class="text-center">Add to cart</td>
            </tr>
          <tr class="orderRow" *ngFor="let item of trophyRegularItems">
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td class="text-end">{{ getPrice(item) | currency }}</td>
              <td class="text-center"><button type="button" class="mt-auto mx-auto add-to-cart-button" (click)="addToCart(item)" [disabled]="isSoldOut(item) || productNotAvailable">Add to Cart</button></td>
          </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row shop_page_content">
    <div class="col-md-10 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner2">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner2">
          <h2>Non-Regular Classes</h2>
          <table class="table table-bordered table-hover table-sm">
            <tr>
              <td>Name</td>
              <td>Description</td>
              <td>Price</td>
              <td class="text-center">Add to cart</td>
            </tr>
            <tr class="orderRow" *ngFor="let item of trophyNonRegularItems">
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td class="text-end">{{ getPrice(item) | currency }}</td>
              <td class="text-center"><button type="button" class="mt-auto mx-auto add-to-cart-button" (click)="addToCart(item)" [disabled]="isSoldOut(item) || productNotAvailable">Add to Cart</button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row shop_page_content">
    <div class="col-md-10 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner3">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner3">
          <h2>Sweeps Classes</h2>
          <table class="table table-bordered table-hover table-sm">
            <tr>
              <td>Name</td>
              <td>Description</td>
              <td>Price</td>
              <td class="text-center">Add to cart</td>
            </tr>
            <tr class="orderRow" *ngFor="let item of trophySweepsItems">
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td class="text-end">{{ getPrice(item) | currency }}</td>
              <td class="text-center"><button type="button" class="mt-auto mx-auto add-to-cart-button" (click)="addToCart(item)" [disabled]="isSoldOut(item) || productNotAvailable">Add to Cart</button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row shop_page_content">
    <div class="col-md-10 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner4">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner4">
          <h2>Beginner Puppy Classes</h2>
          <table class="table table-bordered table-hover table-sm">
            <tr>
              <td>Name</td>
              <td>Description</td>
              <td>Price</td>
              <td class="text-center">Add to cart</td>
            </tr>
            <tr class="orderRow" *ngFor="let item of trophyBeginnerPuppyItems">
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td class="text-end">{{ getPrice(item) | currency }}</td>
              <td class="text-center"><button type="button" class="mt-auto mx-auto add-to-cart-button" (click)="addToCart(item)" [disabled]="isSoldOut(item) || productNotAvailable">Add to Cart</button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row shop_page_content">
    <div class="col-md-10 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner5">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner5">
          <h2>Obedience Classes</h2>
          <table class="table table-bordered table-hover table-sm">
            <tr>
              <td>Name</td>
              <td>Description</td>
              <td>Price</td>
              <td class="text-center">Add to cart</td>
            </tr>
            <tr class="orderRow" *ngFor="let item of trophyObedienceItems">
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td class="text-end">{{ getPrice(item) | currency }}</td>
              <td class="text-center"><button type="button" class="mt-auto mx-auto add-to-cart-button" (click)="addToCart(item)" [disabled]="isSoldOut(item) || productNotAvailable">Add to Cart</button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row shop_page_content">
    <div class="col-md-10 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner6">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner6">
          <h2>Rally Classes</h2>
          <table class="table table-bordered table-hover table-sm">
            <tr>
              <td>Name</td>
              <td>Description</td>
              <td>Price</td>
              <td class="text-center">Add to cart</td>
            </tr>
            <tr class="orderRow" *ngFor="let item of trophyRallyItems">
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td class="text-end">{{ getPrice(item) | currency }}</td>
              <td class="text-center"><button type="button" class="mt-auto mx-auto add-to-cart-button" (click)="addToCart(item)" [disabled]="isSoldOut(item) || productNotAvailable">Add to Cart</button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row shop_page_content">
    <div class="col-md-10 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner7">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner7">
          <h2>Junior Classes</h2>
          <table class="table table-bordered table-hover table-sm">
            <tr>
              <td>Name</td>
              <td>Description</td>
              <td>Price</td>
              <td class="text-center">Add to cart</td>
            </tr>
            <tr class="orderRow" *ngFor="let item of trophyJuniorsItems">
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td class="text-end">{{ getPrice(item) | currency }}</td>
              <td class="text-center"><button type="button" class="mt-auto mx-auto add-to-cart-button" (click)="addToCart(item)" [disabled]="isSoldOut(item) || productNotAvailable">Add to Cart</button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

