<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1 disabled d-none" aria-disabled="true" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto:{{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-12 col-xs-12">
      <h3 >Show Superintendent</h3>
      <p align="left">The show superintendent will be Moss-Bow-Foley (MBF). Their website is <a href="https://www.infodog.com">www.infodog.com</a></p>

      <h3 >Show Photographer</h3>
      <p style="text-align: center;">Terri Hirsch Photography<br>
        70 Langley Rd • Nazareth, PA 18064<br>
        (610) 393-4302<br>
        <a href="mailto:terrihirschphotography@gmail.com">terrihirschphotography&#64;gmail.com</a>
        <a href="https://www.terrihirschphotography.smugmug.com">www.terrihirschphotography.smugmug.com</a>
      </p>
      <h3 >Premium List Information </h3>
      <p align="left">The 2025 National Specialty Premium List will be emailed
        by the Show Superintendent, MB-F, by March 13, 2025, be available to download from their website at
        <a href="https://www.infodog.com"> www.infodog.com</a> and the NCA website at <a href="https://www.ncanationalspecialty.org"> www.ncanationalspecialty.org</a> </p>

    </div>
  </div>
</div>


